import React from 'react';
import { useAbortController } from './use-abort-controller';
import { useAccessTokenRef } from 'century-core/core-auth/hooks/useAuth';

export const useDataLoader = <ResponseData>(
  request: (token: string, signal?: AbortSignal) => Promise<ResponseData>,
  fetchOnMount = false
) => {
  const [data, setData] = React.useState<ResponseData>();
  const [error, setError] = React.useState<Error>();
  const [loading, setLoading] = React.useState(false);
  const getSignal = useAbortController();
  const token = useAccessTokenRef();

  const load = React.useCallback(async () => {
    setLoading(true);
    setError(undefined);
    await request(token.current, getSignal())
      .then((data: ResponseData) => setData(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [request, token, getSignal]);

  const reset = React.useCallback(() => {
    setError(undefined);
    setLoading(false);
    setData(undefined);
  }, []);

  React.useEffect(() => {
    if (fetchOnMount) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error, load, reset };
};
