import { getLearnerProductDetails, LearnerProduct } from 'century-core/core-apis/cms/learnerProduct';
import { useAccessTokenRef, useAuth, useUserId } from 'century-core/core-auth/hooks/useAuth';
import { useB2C } from 'century-core/core-utils/hooks/useB2C';
import { hasLearnerRole } from 'century-core/core-auth/utils';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProductKey } from '../types';
import Spinner from 'century-core/core-components/Spinner/Spinner';

export type LearnerProductWithKey = LearnerProduct<ProductKey>;

interface LearnerProductContextType {
  product: LearnerProductWithKey | null;
  loading?: boolean;
  error?: Error | null;
  isBondLearner?: boolean;
  hasLXJourneyEnabled?: boolean;
  refetch: (asGuardianSwitchingToLearner?: boolean) => void;
}

const LearnerProductContext = createContext({ product: null, loading: false, error: null } as LearnerProductContextType);

export const useLearnerProductContext = (): LearnerProductContextType => useContext(LearnerProductContext);

export const LearnerProductContextProvider = (props: { children: ReactNode; dependantId?: string }) => {
  const auth = useAuth();
  const isLearner = hasLearnerRole(auth);
  const userId = useUserId();
  const isB2C = useB2C();
  const [product, setProduct] = useState<{ product: LearnerProductWithKey | null; loading: boolean; error: null | Error }>({
    product: null,
    loading: !!isB2C && isLearner,
    error: null,
  });
  const accessTokenRef = useAccessTokenRef();

  // TODO this could be handled by the `useDataLoader`
  const getLearnerProduct = useCallback(
    async (asGuardianSwitchingToLearner: boolean = false) => {
      // eslint-disable-next-line
      if ((!!isB2C && isLearner) || asGuardianSwitchingToLearner) {
        setProduct(p => ({ ...p, loading: true, error: null }));
        await getLearnerProductDetails<ProductKey>(accessTokenRef.current)
          .then(product => setProduct(p => ({ ...p, loading: false, product })))
          .catch(error => setProduct(p => ({ ...p, loading: false, error })));
      }
    },
    [accessTokenRef, isB2C, isLearner]
  );

  useEffect(() => {
    if (!product.loading && !product.error && product.product?.learner?.userId !== userId) {
      getLearnerProduct();
    }
  }, [getLearnerProduct, product, userId]);

  const isBondLearner = useMemo(() => ['bond-premium', 'bond-premium-pro'].includes(product.product?.productKey || ''), [product]);

  /**
   * 'hasLXJourneyEnabled' currently is pretty much the same as 'isBondLearner', but there are some places where 'isBondLearner'
   * is used and is independent from 'hasLXJourneyEnabled'.
   * I've decided to leave 'hasLXJourneyEnabled' as it is more readable, it's not so tight to a type of product (in case we want to
   * add/remove some other product) and it would make it easier to roll out to everybody in the future, without messing with some
   * non LX related bond logic (eg, custom branding/styling)
   */
  const hasLXJourneyEnabled = useMemo(() => !!isB2C && !!isBondLearner, [isB2C, isBondLearner]);

  // LX PHASE 1 - we need to hold until the product is loaded to check whether is BOP or BOPP
  if (product.loading) {
    return <Spinner type="fullpage" />;
  }

  return (
    <LearnerProductContext.Provider value={{ ...product, isBondLearner, hasLXJourneyEnabled, refetch: getLearnerProduct }}>
      {props.children}
    </LearnerProductContext.Provider>
  );
};
